import ServicePlan from "./servicePlan";

const Subscription = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null
            this.createdAt = null
            this.goodThru = null
            this.documents = null
            this.plan = null
            this.active = null;
            this.status = null;
            this.remainingDays = null;
        } else {
            this.id = object.id || null
            this.documents = object.documents
            this.plan = new ServicePlan(object.plan)
            this.status = object.status || null
            this.remainingDays = object.remainingDays;

            if (object.createdAt !== undefined) {
                const createdAt = new Date(object.createdAt);
                const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long',};
                const createdAtStr = createdAt.toLocaleDateString('es-EC', options);
                this.createdAt = createdAtStr.charAt(0).toUpperCase() + createdAtStr.slice(1);
            } else { this.createdAt = "-" }

            if (object.goodThru !== undefined) {
                const goodThru = new Date(object.goodThru);
                const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long',};
                const goodThruStr = goodThru.toLocaleDateString('es-EC', options);
                this.goodThru = goodThruStr.charAt(0).toUpperCase() + goodThruStr.slice(1);
            } else { this.goodThru = "-" }
        }
    }

    get currentStatusBadgeColor() {
        if (this.status == "ACTIVE") { return "green" }
        if (this.status == "SUSPENDED") { return "yellow" }
        if (this.status == "EXPIRED") { return "red" }
        if (this.status == "NOEDOC") { return "red" }
        return "green" // UNUSED
    }

    get currentStatus() {
        if (this.status == "ACTIVE") { return "ACTIVA" }
        if (this.status == "SUSPENDED") { return "SUSPENDIDA" }
        if (this.status == "EXPIRED") { return "EXPIRADA" }
        if (this.status == "NOEDOC") { return "SIN DOCUMENTOS" }
        return "SIN EMPEZAR" // UNUSED
    }
}

export default Subscription;