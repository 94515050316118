const ServicePlan = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null
            this.name = null
            this.price = null
            this.users = null
            this.documents = null
            this.products = null
            this.econActivities = null
            this.imageUrl = null
        } else {
            this.id = object.id || null
            this.name = object.name || null
            this.price = object.price || null
            this.users = object.users || null
            this.documents = object.documents || null
            this.products = object.products || null
            this.econActivities = object.econActivities || null
            this.imageUrl = object.imageUrl || null
        }
    }
}

export default ServicePlan