<template>
	<div class="grid p-fluid">
		<div class="col-12 md:col-8 sm:col-12">
			<div class="card">
				<h4>Detalle de Suscripción</h4>
				<!-- For computer -->
				<div class="grid" id="pcSubscriptionContainer">
					<div class="col-4 flex align-items-center justify-content-center">
						<img v-if="this.currentSubscription.plan !== null" :src="this.baseUrl + this.currentSubscription.plan.imageUrl" alt="Tipo de suscripción" style="width: 100%; margin: 11px 0px;">
					</div>
					<div class="col-1">
						<Divider layout="vertical">
						</Divider>
					</div>
					<div class="col-3 align-items-center justify-content-center">
						<div style="margin-bottom: 20px; text-align: center;">
							<h5>Inicio de suscripción</h5>
							<h6>{{ this.currentSubscription.createdAt }}</h6>
						</div>
						<div style="text-align: center;">
							<h5>Fin de suscripción</h5>
							<h6>{{ this.currentSubscription.goodThru }}</h6>
						</div>
						<div v-show="this.alert">
							<h6 class="alert" style="text-align: center; margin-top: 20px;">{{ this.alertMessage }}</h6>
							<Button label="Renovar" class="p-button-rounded mr-2 mb-2 renew-button" @click="this.renewSubDialog=true" />
						</div>
					</div>
					<div class="col-1">
						<Divider layout="vertical"></Divider>
					</div>
					<div class="col-3 align-items-center justify-content-center">
						<Chart type="doughnut" :data="pieData" style="width: 90%;"/>
						<h6 style="margin-top: 20px; text-align: center;" v-if="this.currentSubscription.plan !== null && this.currentSubscription.plan.name !== 'Empresarial' && this.currentSubscription.status == 'ACTIVE'">{{ this.currentSubscription.documents }} {{ (this.currentSubscription.documents == 1) ? "Documento electrónico disponible" : "Documentos electrónicos disponibles" }}</h6>
						<h6 style="margin-top: 20px; text-align: center;" v-if="this.currentSubscription.plan !== null && this.currentSubscription.plan.name === 'Empresarial' && this.currentSubscription.status == 'ACTIVE'">{{ "Documentos electrónicos ilimitados" }}</h6>
					</div>
				</div>

				<!-- For Mobile -->
				<div id="mobileSubscriptionContainer">
					<div class="col-12 flex align-items-center justify-content-center">
						<img v-if="this.currentSubscription.plan !== null" :src="this.baseUrl + this.currentSubscription.plan.imageUrl" alt="Tipo de suscripción" style="width: 100%; margin: 11px 0px;">
					</div>
					<div class="col-11">
						<Divider layout="horizontal">
						</Divider>
					</div>
					<div class="col-12 align-items-center justify-content-center">
						<Chart type="doughnut" :data="pieData" style="width: 90%;"/>
						<h6 style="margin-top: 20px; text-align: center;" v-if="this.currentSubscription.plan !== null && this.currentSubscription.plan.name !== 'Empresarial'">{{ this.currentSubscription.documents }} {{ (this.currentSubscription.documents == 1) ? "Documento electrónico disponible" : "Documentos electrónicos disponibles" }}</h6>
						<h6 style="margin-top: 20px; text-align: center;" v-if="this.currentSubscription.plan !== null && this.currentSubscription.plan.name === 'Empresarial'">{{ "Documentos electrónicos ilimitados" }}</h6>
					</div>
					<div class="col-11">
						<Divider layout="horizontal"></Divider>
					</div>
					<div class="col-12 align-items-center justify-content-center">
						<div style="margin-bottom: 20px; text-align: center;">
							<h5>Inicio de suscripción</h5>
							<h6>{{ this.currentSubscription.createdAt }}</h6>
						</div>
						<div style="text-align: center;">
							<h5>Fin de suscripción</h5>
							<h6>{{ this.currentSubscription.goodThru }}</h6>
						</div>
						<div v-show="this.alert">
							<h6 class="alert" style="text-align: center; margin-top: 20px;">{{ this.alertMessage }}</h6>
							<Button label="Renovar" class="p-button-rounded mr-2 mb-2 renew-button" @click="this.renewSubDialog=true" />
						</div>
					</div>
				</div>
			</div>
			<div class="card">
				<h5>Registro de Suscripciones</h5>
				<DataView :value="this.subs"  layout="list" :rows="9">
					<template #list="slotProps">
						<div class="col-12">
							<div class="flex flex-column md:flex-row align-items-center p-3 w-full">
								<img :src="this.baseUrl + slotProps.data.plan.imageUrl" :alt="slotProps.data.plan.name" class="my-4 md:my-0 w-9 md:w-10rem shadow-2 mr-5" style="margin: auto;"/>
								<div class="flex-1 text-center md:text-left">
									<div class="mb-3"><label style="font-size: 10px; color: black; font-weight: 500;">COMIENZA</label><br>{{ (slotProps.data.status !== "UNUSED") ? slotProps.data.createdAt : "Sin registro" }}</div>
									<div class="mb-3" style="margin-bottom: 0px; !important"><label style="font-size: 10px; color: black; font-weight: 500;">TERMINA</label><br>{{ (slotProps.data.status !== "UNUSED") ? slotProps.data.goodThru : "Sin registro" }}</div>
								</div>
								<div class="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
									<span :class="'status-badge status-' + slotProps.data.currentStatusBadgeColor" style="margin: auto;">{{slotProps.data.currentStatus}}</span>
								</div>
							</div>
						</div>
					</template>
				</DataView>
			</div>
		</div>
		<Dialog v-model:visible="renewSubDialog" ref="subscriptionDialog" :style="{'max-width': '900px', width: '90%'}" header="Renovar Suscripción" :modal="true" class="p-fluid">
			<h5><b>1)</b> Seleccione el tipo de suscripción</h5>
			<DataView :value="plans" layout="grid" :rows="9" style="width: 100%;">
				<template #grid="slotProps">
					<div class="col-12 md:col-6">
						<div class="card m-3 border-1 surface-border servicePlan" :class="{selected: slotProps.data.name === this.selectedPlan.name}" @click="onPlanSelected(slotProps.data)">
							<div class="text-center">
								<img :src="this.baseUrl + slotProps.data.imageUrl" :alt="slotProps.data.name" class="w-9 shadow-2 my-3 mx-0"/>
								<ul style="text-align: left;" v-show="slotProps.data !== null && slotProps.data.name !== 'Empresarial'">
									<li>{{ slotProps.data.econActivities }} {{ (slotProps.data.econActivities === 1) ? "Actividad Económica" : "Actividades Económicas" }}</li>
									<li>{{ slotProps.data.users }} registros de clientes</li>
									<li>{{ slotProps.data.products }} registros de productos o servicios</li>
									<li>{{ slotProps.data.documents }} Doc. Electrónicos</li>
								</ul>
								<ul style="text-align: left;" v-show="slotProps.data.name === 'Empresarial'">
									<li>Actividades Económicas ilimitadas</li>
									<li>Registros ilimitados de clientes</li>
									<li>Registros ilimitados de productos o servicios</li>
									<li>Doc. Electrónicos ilimitados</li>
								</ul>
							</div>
						</div>
					</div>
				</template>
				<template #footer style="background-color: white;">
					<h4><b>Resumen:</b></h4>
					<h5 style="margin-top: 5px;">Suscripción Seleccionada: <b>{{ this.selectedPlan.name }}</b></h5>
					<h5 style="margin-top: 5px;">Duración de Suscripción: <b>1 año</b></h5>
					<h5 style="margin-top: 5px;">Total: <b>${{ (this.selectedPlan.price * 1.12).toFixed(2) }}</b></h5>
				</template>
			</DataView>
			<h5 ref="section2"><b>2)</b> Realize el pago de <b>${{ (this.selectedPlan.price * 1.12).toFixed(2) }}</b> transfiriendo a una de las siguientes cuentas bancarias, después adjunte el comprobante en la sección inferior y haga click en Enviar</h5>
			<div class="grid p-fluid">
				<div class="col-12 md:col-6">
					<div class="card m-3 border-1 surface-border">
						<div class="text-center">
							<img src="/layout/images/payment/pichincha.png" class="w-9 my-3 mx-0"/>
						</div>
						<ul>
							<li><b>Cuenta de Ahorro: </b> 2203777977</li>
							<li><b>Titular: </b> Paul Estrada León</li>
							<li><b>Cédula: </b> 1310426174</li>
						</ul>
					</div>
				</div>
				<div class="col-12 md:col-6">
					<div class="card m-3 border-1 surface-border">
						<div class="text-center">
							<img src="/layout/images/payment/pacifico.jpeg" class="w-9 my-3 mx-0"/>
						</div>
						<ul>
							<li><b>Cuenta de Ahorro: </b> 1055322220</li>
							<li><b>Titular: </b> Paul Estrada León</li>
							<li><b>Cédula: </b> 1310426174</li>
						</ul>
					</div>
				</div>
				<div class="col-12 md:col-12">
					<FileUpload name="paymentProof" chooseLabel="Adjuntar comprobante" :fileLimit="1" :showUploadButton="false" :showCancelButton="false" accept="image/*" :maxFileSize="10000000" invalidFileLimitMessage="Solo puede seleccionar un comprobante." customUpload  @select="onPaymentProof($event)">
						<template #empty>
							<p>Arrastre el comprobante hasta aquí</p>
						</template>
					</FileUpload>
				</div>
				<div class="col-12 md:col-12">
					<Button label="Enviar" class="p-button-raised p-button-success mr-2 mb-2" @click="saveSubRenewal()" />
				</div>


				<Dialog header="Advertencia" v-model:visible="warningDialog" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" :modal="true">
					<p class="line-height-3 m-0">
						Adjunte un comprobante de la transferencia requerida antes de enviar la solicitud de renovación de Suscripción.
					</p>
					<template #footer>
						<Button label="Entendido" @click="this.warningDialog=false" icon="pi pi-check" class="p-button-outlined"/>
					</template>
				</Dialog>
			</div>
			
			<template #footer>
				<Button label="Cerrar" class="p-button-secondary mr-2 mb-2" style="margin-top: 15px;" @click="this.onSubscriptionDialogClosed()"/>
			</template>
		</Dialog>


		<Dialog header="Solicitud Enviada" v-model:visible="completionDialog" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" :modal="true" :closable="false">
			<p class="line-height-3 m-0">
				Se ha enviado su solicitud de renovación y sera revisada dentro de las proximas 48 horas, por el momento su nueva suscripción esta pre-aprobada.<br> Muchas gracias por su preferencia.
			</p>
			<template #footer>
				<Button label="Entendido" @click="this.completionDialog=false" icon="pi pi-check" class="p-button-outlined"/>
			</template>
		</Dialog>
	</div>
</template>
<script>
	import httpRequest from '../../service/httpRequest';		
	import Subscription from '../../models/subscription';
	import ServicePlan from '../../models/servicePlan';

	export default {
		data() {
			return {
				baseUrl: this.$baseURL,
				alert: true,
				alertMessage: "",
				currentSubscription: new Subscription(),
				plans: null,
				selectedPlan: new ServicePlan(),
				subs: null,
				selectedProof: null,
				renewSubDialog: false,
				warningDialog: false,
				completionDialog: false,
				pieData: {
					labels: [''],
					datasets: [
						{
							data: [0, 0],
							backgroundColor: [
								"#18407F",
								"#EEEDEF"
							],
							hoverBackgroundColor: [
								"#18407F",
								"#EEEDEF"
							]
						}
					]
				},
			}
		},
		async created() {
			this.getSubscriptions();
		},
		methods: {
			async getSubscriptions() {
				const response = await httpRequest.getSubscriptions();
				if (response.status === 200) {
					this.handleSubsResponse(response.data);
				} 
			},
			async getPlans() {
				const response = await httpRequest.getServicePlans();
				if (response.status === 200) {
					this.plans = response.data.map(sub => { return new ServicePlan(sub) });
				} 
			},
			onPlanSelected(plan) {
				this.selectedPlan = plan;
				var element = this.$refs["section2"];
				element.scrollIntoView({behavior: 'smooth'});
			},
			onPaymentProof(event) {
				this.selectedProof = event.files[0];
			},
			onSubscriptionDialogClosed() {
				this.selectedPlan = new ServicePlan();
				this.renewSubDialog = false;
			},
			async saveSubRenewal() {
				if (this.selectedProof === null) { this.warningDialog = true; return }
				// Create form
				const form = new FormData();
				if (this.selectedPlan.id !== null) { form.append('planId', this.selectedPlan.id) }
				if (this.selectedProof !== null) { form.append('proof', this.selectedProof) }

				// Send to server
				const response = await httpRequest.renewSubscription(form);
				if (response.status === 200) {
					this.renewSubDialog = false;
					this.completionDialog = true;
					this.handleSubsResponse(response.data);
				}
			},
			handleSubsResponse(data) {
				let subsData = data["subs"];
				this.subs = subsData.map(data => { return new Subscription(data) });
				this.currentSubscription = new Subscription(data["current"]);
				let hasUnusedSub = this.subs.filter((sub) => sub.status == "UNUSED").length > 0;

				// Setup analytics and alert
				if (hasUnusedSub) { // Has an unused subscription
					this.alert = false 
					const difference = (this.currentSubscription.remainingDays > 365) ? 0 : (365-this.currentSubscription.remainingDays)
					this.pieData.datasets[0].data = [this.currentSubscription.remainingDays, difference]
					this.pieData.labels = [this.currentSubscription.remainingDays + " días restantes"]
				} else if (this.currentSubscription.plan.name === "Empresarial") { // Has Coorporative sub
					if (this.currentSubscription.remainingDays <= 0) { 
						this.alert = true;
						this.alertMessage = "Su suscripción ha expirado, renueve para reestablecer su cuenta."
						this.pieData.datasets[0].data = [0, 365]
						this.pieData.labels = ["Días restantes"]
						this.getPlans();
					} else if (this.currentSubscription.remainingDays <= 15) { // Warning on expire date
						this.alert = true;
						let dayStr = (this.currentSubscription.remainingDays == 1) ? "Queda 1 día" : "Quedan " + this.currentSubscription.remainingDays + " días"
						this.alertMessage = dayStr + " antes que su suscripción expire"
						this.alertMessage += ". Sugerimos renovar pronto."
						this.pieData.datasets[0].data = [this.currentSubscription.remainingDays, (365-this.currentSubscription.remainingDays)]
						this.pieData.labels = [this.currentSubscription.remainingDays + " días restantes"]
						this.pieData.datasets[0].backgroundColor = ["#F7AB47", "#EEEDEF"]
						this.pieData.datasets[0].hoverBackgroundColor = ["#F7AB47", "#EEEDEF"]
						this.getPlans();
					} else { // Subscription has no issue
						this.alert = false 
						const difference = (this.currentSubscription.remainingDays > 365) ? 0 : (365-this.currentSubscription.remainingDays)
						this.pieData.datasets[0].data = [this.currentSubscription.remainingDays, difference]
						this.pieData.labels = [this.currentSubscription.remainingDays + " días restantes"]
					}
				} else { // Limited subscriptions
					if (this.currentSubscription.remainingDays <= 0) { // Expired
						this.alert = true;
						this.alertMessage = "Su suscripción ha expirado, renueve para reestablecer su cuenta."
						this.pieData.datasets[0].data = [0, 365]
						this.pieData.labels = ["Días restantes"]
						this.getPlans();
					} else if (this.currentSubscription.documents <= 0) { // No Documents
						this.alert = true;
						this.alertMessage = "Sus documentos electrónicos se han agotado, renueve para reestablecer su cuenta."
						this.pieData.datasets[0].data = [this.currentSubscription.remainingDays, (365-this.currentSubscription.remainingDays)]
						this.pieData.labels = [this.currentSubscription.remainingDays + " días restantes"]
						this.pieData.datasets[0].backgroundColor = ["#F7AB47", "#EEEDEF"]
						this.pieData.datasets[0].hoverBackgroundColor = ["#F7AB47", "#EEEDEF"]
						this.getPlans();
					} else if (this.currentSubscription.remainingDays <= 15 && this.currentSubscription.documents <= 15) { // Warning on both no-documents and expire date
						this.alert = true;
						let dayStr = (this.currentSubscription.remainingDays == 1) ? "Queda 1 día" : "Quedan " + this.currentSubscription.remainingDays + " días"
						let docAmountStr = (this.currentSubscription.documents == 1) ? ", y cuenta con 1 doc. electrónico disponible" : ", y cuenta con " + this.currentSubscription.documents + " doc. electrónicos disponibles"
						this.alertMessage = dayStr + " antes que su suscripción expire" + docAmountStr
						this.alertMessage += ". Sugerimos renovar pronto."

						this.pieData.datasets[0].data = [this.currentSubscription.remainingDays, (365-this.currentSubscription.remainingDays)]
						this.pieData.labels = [this.currentSubscription.remainingDays + " días restantes"]
						this.pieData.datasets[0].backgroundColor = ["#F7AB47", "#EEEDEF"]
						this.pieData.datasets[0].hoverBackgroundColor = ["#F7AB47", "#EEEDEF"]
						this.getPlans();
					} else if (this.currentSubscription.remainingDays <= 15) { // Warning on expire date
						this.alert = true;
						let dayStr = (this.currentSubscription.remainingDays == 1) ? "Queda 1 día" : "Quedan " + this.currentSubscription.remainingDays + " días"
						this.alertMessage = dayStr + " antes que su suscripción expire"
						this.alertMessage += ". Sugerimos renovar pronto."
					
						this.pieData.datasets[0].data = [this.currentSubscription.remainingDays, (365-this.currentSubscription.remainingDays)]
						this.pieData.labels = [this.currentSubscription.remainingDays + " días restantes"]
						this.pieData.datasets[0].backgroundColor = ["#F7AB47", "#EEEDEF"]
						this.pieData.datasets[0].hoverBackgroundColor = ["#F7AB47", "#EEEDEF"]
						this.getPlans();
					} else if (this.currentSubscription.documents <= 15) { // Warning on no-documents
						this.alert = true;
						let docAmountStr = (this.currentSubscription.documents == 1) ? "1 documento electrónico" : this.currentSubscription.documents + " documentos electrónicos"
						this.alertMessage = "Cuenta con " + docAmountStr + ". Sugerimos renovar pronto."
					
						this.pieData.datasets[0].data = [this.currentSubscription.remainingDays, (365-this.currentSubscription.remainingDays)]
						this.pieData.labels = [this.currentSubscription.remainingDays + " días restantes"]
						this.pieData.datasets[0].backgroundColor = ["#F7AB47", "#EEEDEF"]
						this.pieData.datasets[0].hoverBackgroundColor = ["#F7AB47", "#EEEDEF"]
						this.getPlans();
					} else { // Current subscription has no issue
						this.alert = false;
						const difference = (this.currentSubscription.remainingDays > 365) ? 0 : (365-this.currentSubscription.remainingDays)
						this.pieData.datasets[0].data = [this.currentSubscription.remainingDays, difference]
						this.pieData.labels = [this.currentSubscription.remainingDays + " días restantes"]
					}
				}
				
			
			}
		}
	}
</script>
<style lang="scss">
	// @import './App.scss';
	@import '../../assets/demo/badges.scss';
	// #subscriptionDialog {
	// 	scroll-behavior: smooth;
	// }
	.alert {
		color: red;
		margin-top: 10px;
		padding: 0px;
	}
	h5 {
		margin-bottom: 5px;
	}
	h6 {
		margin: 0px;
	}
	.middle-separator {
		border-left: 1px solid lightgray;
		border-right: 1px solid lightgray;
		text-align: center;
		padding-top: 20px;
	}
	.renew-button:hover {
		background: #f18700 !important;
		border: 1px solid #f18700 !important;
	}
	.status-badge {
		border-radius: var(--border-radius);
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;
	}
	.servicePlan:hover {
		border: 2px solid #f18700 !important; 
	}
	.selected {
		border: 2px solid #f18700 !important; 
	}
	.status-badge {
		&.status-green {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-red {
			background: #FFCDD2;
			color: #C63737;
		}

		&.status-yellow {
			background: #FEEDAF;
			color: #8A5340;
		}

		&.status-orange {
			background: #ffa177;
			color: #da2727;
		}

		&.status-blue {
			background: #B3E5FC;
			color: #23677B;
		}

		&.status-gray {
			background: #d6d8d9;
			color: #464747;
		}
	}
	.p-dataview-footer {
		background-color: white !important;
	}

	// Pc
	@media screen and (min-width: 750px) {
		#pcSubscriptionContainer {
			display: flex;	
		}

		#mobileSubscriptionContainer {
			display: none;	
		}
	}

	// Mobile
	@media screen and (max-width: 750px) {
		#pcSubscriptionContainer {
			display: none;	
		}

		#mobileSubscriptionContainer {
			display: block;	
		}
	}
	
</style>